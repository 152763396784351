export const realtorData = {
  // email: 'contacto@unne.cl',
  email:'fabians@bidata.cl',
  phone:953858376,
  address: 'RentalProp Spa Apoquindo 6410 Of.605, Las condes',

};

export const timeData = {
  daysWeek:'Lunes a viernes',
  times: '8:30 - 1800',
  weekend: 'Sábado',
  timesWeekend:'09:00 - 14:30'

};